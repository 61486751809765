import React, { Component } from 'react';
import styled from 'styled-components';
import { Checkbox } from 'semantic-ui-react';
import CloseIcon from './CloseIcon';
import { colors } from './utils/colors';

export default class CityCheckbox extends Component {
  state = {
    openFullList: false,
    selectAll: false,
  };

  closeModal = () => this.setState({ openFullList: false });

  closeDimmerClick = (e) => {
    return e.target.id === 'modalCities' ? this.closeModal() : false;
  };

  toggleCities = (e, { value }) => {
    const { cities, setSelectedCities } = this.props;
    const updatedCities = {
      ...cities,
      [value]: !cities[value],
    };

    setSelectedCities(updatedCities);
  };

  generateCitiesCheckbox = (isFullList) => {
    const { cities } = this.props;
    const { openFullList } = this.state;
    const sortedBySelected = Object.keys(cities).sort((a, b) => {
      if ((!cities[a] && !cities[b]) || (cities[a] && cities[b])) {
        return 0;
      } else if (!cities[a] && cities[b]) {
        return 1;
      } else {
        return -1;
      }
    });

    const sortedCities = sortedBySelected.reduce((result, city) => {
      result[city] = cities[city];
      return result;
    }, {});

    return Object.keys(sortedCities).map((city, index) => (
      <Checkbox
        key={index}
        name={city}
        label={city}
        value={city}
        checked={sortedCities[city]}
        onChange={this.toggleCities}
        style={{ margin: isFullList ? '0.75rem 0.5rem' : '0.25rem' }}
      />
    ));
  };

  render() {
    const { cities } = this.props;
    const { openFullList, selectAll } = this.state;

    return (
      <div>
        {this.generateCitiesCheckbox(openFullList).splice(0, 5)}
        <span
          style={{ marginLeft: '0.5rem', cursor: 'pointer' }}
          onClick={() => this.setState({ openFullList: true })}
        >
          ...更多選項
        </span>

        {openFullList ? (
          <StyledModal
            open={false}
            onClick={this.closeDimmerClick}
            id="modalCities"
          >
            <ModalBody>
              <h2 style={{ marginBottom: '2rem' }}>選擇圖書館</h2>
              <div style={{ marginBottom: '0.75rem' }}>
                <Checkbox
                  key={0}
                  name="all"
                  label="全選"
                  checked={selectAll}
                  onChange={() => {
                    Object.keys(cities).forEach(
                      (city) => (cities[city] = !selectAll ? true : false)
                    );

                    this.props.setSelectedCities(cities);
                    this.setState({ selectAll: !selectAll });
                  }}
                />
              </div>
              <div
                style={{ width: '95%', textAlign: 'left', margin: '0 auto' }}
              >
                {this.generateCitiesCheckbox(openFullList)}
              </div>
              <CloseIcon close={this.closeModal} />
              <StyledButton onClick={this.closeModal}>確認</StyledButton>
            </ModalBody>
          </StyledModal>
        ) : null}
      </div>
    );
  }
}

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  vertical-align: middle;
  z-index: 1000;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  position: relative;
  background-color: #fff;
  border: none;
  color: #000;
  text-align: center;
  width: 55rem;
  min-height: 25rem;
  margin: 3rem auto;
  border-radius: 7px;
  overflow: hidden;
  padding: 2rem;

  @media only screen and (max-width: 420px) {
    width: 90%;
  }

  .title {
    @media only screen and (max-width: 420px) {
      font-size: 16px;
    }
  }

  img {
    @media only screen and (max-width: 420px) {
      width: 85%;
    }
  }
`;

const StyledButton = styled.button`
  height: 2rem;
  width: 5rem;
  margin-top: 2rem;
  border-radius: 8px;
  background-color: ${colors.mainGreen};
  border: 2px solid ${colors.mainGreen};
  color: white;
`;
