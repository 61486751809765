import React, { Component } from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import MultiLangText from './MultiLangText';
import BuyMeCoffee from './BuyMeCoffee';
import Result from './Result';
import styled from 'styled-components';
import Loading from './loading';
import Preview from './Preview';
import RankSearch from './RankSearch';
import NavBar from './NavBar';
import './i18n';
import backgroundImg from './images/library.jpg';
import {
  getUrls,
  getUdnLinkedUrls,
  getHyReadLinkedUrls,
  cityTranslate,
} from './utils/helpers';
import HeadwayWidget from './HeadwayWidget';
import CityCheckbox from './CityCheckbox';
import { colors } from './utils/colors';

let isOnComposition = false;
const screenWidth = window && window.innerWidth;
const mobildWidth = 1000;

export default class Library extends Component {
  state = {
    search: '',
    loading: false,
    result: {},
    lang: null,
    rankList: '',
    rankListVisible: false,
    subRankListVisible: false,
    tooLongWarning: false,
    noCityWarning: false,
    previewNumbers: [],
    cities: {},
  };

  componentDidMount() {
    const script = document.createElement('script');
    const citiesFromStorage = JSON.parse(localStorage.getItem('cities'));
    const defaultCities = {
      國立臺灣圖書館: true,
      國資圖電子書平台: true,
      臺北市立圖書館: true,
      新北市立圖書館: true,
      桃園市立圖書館: true,
      臺中市立圖書館: true,
      臺南市立圖書館: true,
      高雄市立圖書館: true,
      國家圖書館: true,
      基隆市文化局: false,
      新竹市文化局圖書館: false,
      新竹縣政府文化局: false,
      苗栗市立圖書館: false,
      苗栗縣立圖書館: false,
      彰化雲端電子書庫: false,
      嘉義市政府文化局: false,
      宜蘭縣政府文化局: false,
      花蓮縣文化局: false,
      臺東縣政府文化處: false,
      澎湖縣圖書館: false,
      金門縣文化局: false,
      連江縣公共圖書館: false,
    };

    script.src = '//cdn.holmesmind.com/js/init.js';
    script.async = true;

    document.body.appendChild(script);

    this.setState({
      cities: citiesFromStorage ? citiesFromStorage : defaultCities,
    });

    const queryParameters = new URLSearchParams(window.location.search);
    const search = queryParameters.get('search');
    if (search) {
      this.setState({ search }, () => this.handleSubmit());
    }
  }

  handleChange = (e) => {
    const { value } = e.target;
    const { search, tooLongWarning } = this.state;
    const specialCharacters = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    if (e.target instanceof HTMLInputElement && !isOnComposition) {
      if (e.key === 'Enter') {
        if (search) {
          return this.handleSubmit();
        }
      }
    }

    if (value.length > 5 || specialCharacters.test(value)) {
      this.setState({ tooLongWarning: true });
    } else if (tooLongWarning) {
      this.setState({ tooLongWarning: false });
    }

    return this.setState({ search: value });
  };

  handleSubmit = () => {
    const { search, cities } = this.state;
    const urls = getUrls(search);

    localStorage.setItem('cities', JSON.stringify(cities));

    if (!Object.keys(cities).find((city) => cities[city])) {
      return this.setState({ noCityWarning: true });
    }

    this.setState({ result: {} });
    this.sendRequest(urls);
  };

  handleComposition = (e: KeyboardEvent) => {
    if (e.type === 'compositionend') {
      isOnComposition = false;
    } else {
      isOnComposition = true;
    }
  };

  setSelectedCities = (cities) =>
    this.setState({ cities, noCityWarning: false });

  setSearchFirstThenSubmit = (str) => {
    this.setState({ search: str }, this.handleSubmit);
  };

  setRankList = (rank) => {
    this.setState({ rankList: rank, result: {} });
  };

  setRankListVisible = (visible) => {
    this.setState({ rankListVisible: visible });
  };

  setSubRankListVisible = (visible) => {
    this.setState({ subRankListVisible: visible });
  };

  setPreviewNumbers = (nums) => {
    const { previewNumbers } = this.state;

    if (!previewNumbers.length) {
      this.setState({ previewNumbers: nums });
    }

    return;
  };

  async sendRequest(urls) {
    let searchResult = {};
    const isFound = (data, place) => {
      const notFoundFormat = [
        '共 0 頁',
        '無此類書刊',
        '找不到符合的書本或雜誌',
      ];
      let notFound = false;

      for (let n of notFoundFormat) {
        if (data.includes(n)) {
          notFound = true;
        }
      }

      if (place === 'Public Information hyread') {
        const record = JSON.parse(data).record;

        if (!record.length) notFound = true;
      }

      if (place.includes('udn')) {
        if (!data || !Object.keys(JSON.parse(data).data).length) {
          notFound = true;
        } else if (
          data &&
          JSON.parse(data) &&
          JSON.parse(data).data &&
          JSON.parse(data).data.booklist
        ) {
          const udnResult = JSON.parse(data).data.booklist;

          if (!udnResult.length) {
            notFound = true;
          }
        }
      } else if (place.includes('cloud')) {
        if (
          data &&
          JSON.parse(data) &&
          JSON.parse(data).hits &&
          JSON.parse(data).hits.hit
        ) {
          const cloudResult = JSON.parse(data).hits.hit;

          if (!cloudResult.length) {
            notFound = true;
          }
        }
      }

      if (notFound) {
        searchResult[place] = 'N/A';
      } else {
        searchResult[place] = data;
      }
    };

    this.setState({ loading: true });

    const { cities } = this.state;
    const promises = [];

    const taiwanCloud = fetch(urls['Taiwan cloud'])
      .then((res) => res.text())
      .then((data) => ({
        response: data,
        promise: 'Taiwan cloud',
      }));
    // const taiwanIread = fetch(urls['Taiwan iread'])
    //   .then((res) => res.text())
    //   .then((data) => ({
    //     response: data,
    //     promise: 'Taiwan iread',
    //   }));
    promises.push(taiwanCloud);
    // promises.push(taiwanIread);

    const fetchHyReadContent = (firstReq, lib) => {
      firstReq = firstReq.replace(/\s/g, '');

      let start = firstReq.indexOf('slp_searchResultHtmlAjax.jsp') + 42,
        endBracket = start;

      while (firstReq[endBracket] !== '}' && endBracket < firstReq.length) {
        endBracket++;
      }

      const formDataStr = firstReq.substring(start, endBracket - 1);
      const encodedKey = encodeURIComponent('info');
      const encodedValue = encodeURIComponent(formDataStr);
      const formBody = encodedKey + '=' + encodedValue;

      return fetch(
        `https://customcorsanywhere.herokuapp.com/${
          getHyReadLinkedUrls()[lib]
        }/mservice/slp_searchResultHtmlAjax.jsp`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
          },
          body: formBody,
        }
      );
    };

    if (cities['國立臺灣圖書館']) {
      const taiwan = fetch(urls['Taiwan hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Taiwan hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Taiwan hyread',
          };
        });
      const taiwanUdn = fetch(urls['Taiwan udn'])
        .then((res) => res.text())
        .then((data) => ({
          response: data,
          promise: 'Taiwan udn',
        }));
      promises.push(taiwan);
      promises.push(taiwanUdn);
    }

    if (cities['國資圖電子書平台']) {
      const publicInformation = fetch(urls['Public Information hyread'])
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Public Information hyread',
          };
        });
      const publicInformationUdn = fetch(urls['Public Information udn'])
        .then((res) => res.text())
        .then((data) => ({
          response: data,
          promise: 'Public Information udn',
        }));
      promises.push(publicInformation);
      promises.push(publicInformationUdn);
    }

    if (cities['基隆市文化局']) {
      const keelung = fetch(urls['Keelung hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Keelung hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Keelung hyread',
          };
        });
      const keelungUdn = fetch(urls['Keelung udn'])
        .then((res) => res.text())
        .then((data) => ({
          response: data,
          promise: 'Keelung udn',
        }));
      promises.push(keelung);
      promises.push(keelungUdn);
    }

    if (cities['臺北市立圖書館']) {
      const taipei = fetch(urls['Taipei hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Taipei hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Taipei hyread',
          };
        });
      const taipeiUdn = fetch(urls['Taipei udn'])
        .then((res) => res.text())
        .then((data) => ({
          response: data,
          promise: 'Taipei udn',
        }));
      promises.push(taipei);
      promises.push(taipeiUdn);
    }

    if (cities['新北市立圖書館']) {
      const newTaipeiCity = fetch(urls['New Taipei City hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'New Taipei City hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'New Taipei City hyread',
          };
        });
      const newTaipeiCityUdn = fetch(urls['New Taipei City udn'])
        .then((res) => res.text())
        .then((data) => ({
          response: data,
          promise: 'New Taipei City udn',
        }));
      promises.push(newTaipeiCity);
      promises.push(newTaipeiCityUdn);
    }

    if (cities['臺中市立圖書館']) {
      const taichung = fetch(urls['Taichung hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Taichung hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Taichung hyread',
          };
        });
      const taichungUdn = fetch(urls['Taichung udn'])
        .then((res) => res.text())
        .then((data) => ({
          response: data,
          promise: 'Taichung udn',
        }));
      promises.push(taichung);
      promises.push(taichungUdn);
    }

    if (cities['國家圖書館']) {
      const national = fetch(urls['National hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'National hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'National hyread',
          };
        });
      const nationalUdn = fetch(urls['National udn'])
        .then((res) => res.text())
        .then((data) => ({
          response: data,
          promise: 'National udn',
        }));
      promises.push(national);
      promises.push(nationalUdn);
    }

    if (cities['桃園市立圖書館']) {
      const taoyuan = fetch(urls['Taoyuan hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Taoyuan hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Taoyuan hyread',
          };
        });
      const taoyuanUdn = fetch(urls['Taoyuan udn'])
        .then((res) => res.text())
        .then((data) => ({
          response: data,
          promise: 'Taoyuan udn',
        }));
      promises.push(taoyuan);
      promises.push(taoyuanUdn);
    }

    if (cities['新竹市文化局圖書館']) {
      const hsinchu = fetch(urls['Hsinchu hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Hsinchu hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Hsinchu hyread',
          };
        });
      promises.push(hsinchu);
    }

    if (cities['新竹縣政府文化局']) {
      const hsinchuCounty = fetch(urls['Hsinchu County hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Hsinchu County hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Hsinchu County hyread',
          };
        });
      promises.push(hsinchuCounty);
    }

    if (cities['苗栗市立圖書館']) {
      const miaoli = fetch(urls['Miaoli hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Miaoli hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Miaoli hyread',
          };
        });
      promises.push(miaoli);
    }

    if (cities['苗栗縣立圖書館']) {
      const miaoliCounty = fetch(urls['Miaoli County hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Miaoli County hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Miaoli County hyread',
          };
        });
      const miaoliCountyUdn = fetch(urls['Miaoli County udn'])
        .then((res) => res.text())
        .then((data) => ({
          response: data,
          promise: 'Miaoli County udn',
        }));
      promises.push(miaoliCounty);
      promises.push(miaoliCountyUdn);
    }

    if (cities['彰化雲端電子書庫']) {
      const changhua = fetch(urls['Changhua hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Changhua hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Changhua hyread',
          };
        });
      promises.push(changhua);
    }

    if (cities['嘉義市政府文化局']) {
      const chiayi = fetch(urls['Chiayi hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Chiayi hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Chiayi hyread',
          };
        });
      const chiayiUdn = fetch(urls['Chiayi udn'])
        .then((res) => res.text())
        .then((data) => ({
          response: data,
          promise: 'Chiayi udn',
        }));
      promises.push(chiayi);
      promises.push(chiayiUdn);
    }

    if (cities['臺南市立圖書館']) {
      const tainan = fetch(urls['Tainan hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Tainan hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Tainan hyread',
          };
        });
      promises.push(tainan);
    }

    if (cities['高雄市立圖書館']) {
      const kaohsiung = fetch(urls['Kaohsiung hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Kaohsiung hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Kaohsiung hyread',
          };
        });
      const kaohsiungUdn = fetch(urls['Kaohsiung udn'])
        .then((res) => res.text())
        .then((data) => ({
          response: data,
          promise: 'Kaohsiung udn',
        }));
      promises.push(kaohsiung);
      promises.push(kaohsiungUdn);
    }

    if (cities['宜蘭縣政府文化局']) {
      const yilan = fetch(urls['Yilan hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Yilan hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Yilan hyread',
          };
        });
      const yilanUdn = fetch(urls['Yilan udn'])
        .then((res) => res.text())
        .then((data) => ({
          response: data,
          promise: 'Yilan udn',
        }));
      promises.push(yilan);
      promises.push(yilanUdn);
    }

    if (cities['花蓮縣文化局']) {
      const hualien = fetch(urls['Hualien hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Hualien hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Hualien hyread',
          };
        });
      const hualienUdn = fetch(urls['Hualien udn'])
        .then((res) => res.text())
        .then((data) => ({
          response: data,
          promise: 'Hualien udn',
        }));
      promises.push(hualien);
      promises.push(hualienUdn);
    }

    if (cities['臺東縣政府文化處']) {
      const taidong = fetch(urls['Taidong hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Taidong hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Taidong hyread',
          };
        });
      const taidongUdn = fetch(urls['Taidong udn'])
        .then((res) => res.text())
        .then((data) => ({
          response: data,
          promise: 'Taidong udn',
        }));
      promises.push(taidong);
      promises.push(taidongUdn);
    }

    if (cities['澎湖縣圖書館']) {
      const penghu = fetch(urls['Penghu hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Penghu hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Penghu hyread',
          };
        });
      const penghuUdn = fetch(urls['Penghu udn'])
        .then((res) => res.text())
        .then((data) => ({
          response: data,
          promise: 'Penghu udn',
        }));
      promises.push(penghu);
      promises.push(penghuUdn);
    }

    if (cities['金門縣文化局']) {
      const golden = fetch(urls['Golden hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Golden hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Golden hyread',
          };
        });
      promises.push(golden);
    }

    if (cities['連江縣公共圖書館']) {
      const lienchiang = fetch(urls['Lienchiang hyread'])
        .then((res) => res.text())
        .then((data) => {
          return fetchHyReadContent(data, 'Lienchiang hyread');
        })
        .then((res) => res.text())
        .then((searchData) => {
          return {
            response: searchData,
            promise: 'Lienchiang hyread',
          };
        });
      promises.push(lienchiang);
    }

    try {
      const queryLibriesResult = await Promise.all(promises);

      for (let i = 0; i < queryLibriesResult.length; i++) {
        isFound(queryLibriesResult[i].response, queryLibriesResult[i].promise);
      }

      this.setState({ loading: false });
      this.setState({ result: searchResult });
    } catch (error) {
      console.log(error);
    }
  }

  updateLanguage = (lang) => this.setState({ lang });

  displayContent = () => {
    const {
      loading,
      search,
      result,
      rankList,
      rankListVisible,
      subRankListVisible,
      previewNumbers,
    } = this.state;

    if (loading) {
      return <Loading active />;
    } else if (!Object.keys(result).length && !rankList) {
      return (
        <Preview
          setSearchFirstThenSubmit={this.setSearchFirstThenSubmit}
          previewNumbers={previewNumbers}
          setPreviewNumbers={this.setPreviewNumbers}
        />
      );
    } else if (!Object.keys(result).length && rankList) {
      return (
        <div>
          <RankSearch
            rankList={rankList}
            subRankListVisible={subRankListVisible}
            setRankListVisible={this.setRankListVisible}
            setSubRankListVisible={this.setSubRankListVisible}
            setSearchFirstThenSubmit={this.setSearchFirstThenSubmit}
          />
          <BuyMeCoffee />
        </div>
      );
    }

    return (
      <div>
        <Result allLibraryResult={result} search={search} />
        {screenWidth < mobildWidth ? (
          <div
            dangerouslySetInnerHTML={{
              __html:
                '<ins \
                class="clickforceads" \
                style="display: inline-block;" \
                data-ad-zone="12456" \
              ></ins>',
            }}
          ></div>
        ) : null}
        <div style={{ display: 'flex', justifyContent: 'space-around' }}>
          {screenWidth < mobildWidth ? null : (
            <div
              style={{ position: 'relative' }}
              dangerouslySetInnerHTML={{
                __html:
                  '<ins \
                class="clickforceads" \
                style="transform: scale(0.8);" \
                data-ad-zone="12455" \
              ></ins>',
              }}
            ></div>
          )}
          <BuyMeCoffee />
          {screenWidth < mobildWidth ? null : (
            <div
              style={{ position: 'relative' }}
              dangerouslySetInnerHTML={{
                __html:
                  '<ins \
                class="clickforceads" \
                style="align-self: flex-end; transform: scale(0.8);" \
                data-ad-zone="12454" \
              ></ins>',
              }}
            ></div>
          )}
        </div>
      </div>
    );
  };

  render() {
    const {
      loading,
      result,
      search,
      lang,
      cities,
      rankList,
      rankListVisible,
      subRankListVisible,
      tooLongWarning,
      noCityWarning,
    } = this.state;

    return (
      <Home>
        <h2 style={{ margin: 0 }}>
          <a href="https://taiwanlibrarysearch.herokuapp.com/">
            台灣圖書館電子書搜尋
          </a>
        </h2>
        <h3 style={{ margin: '1rem auto' }}>
          <MultiLangText text="search_public_libraries" />
        </h3>
        <div
          style={{
            position: 'relative',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div>
            <SearchInput
              placeholder={
                !lang || lang === 'zhtw'
                  ? '書籍關鍵字/作者/ISBN ...'
                  : 'Book/Author/ISBN ...'
              }
              name="institution"
              onChange={this.handleChange}
              onKeyDown={this.handleChange}
              onFocus={() => {
                this.setRankListVisible(false);
                this.setSubRankListVisible(false);
              }}
              onCompositionStart={this.handleComposition}
              onCompositionUpdate={this.handleComposition}
              onCompositionEnd={this.handleComposition}
              value={search}
            />

            {this.props.adBlockDetected ? (
              <TurnOffAdBlocker>
                <i className="exclamation circle icon"></i>
                請關閉廣告阻擋程式
              </TurnOffAdBlocker>
            ) : (
              <SearchButton
                onClick={this.handleSubmit}
                disabled={loading || !search}
                disable={loading || !search}
              >
                <i className="search icon"></i>
                <MultiLangText text="submit" />
              </SearchButton>
            )}
          </div>
        </div>
        {tooLongWarning ? (
          <TooLongWarning>
            <Icon name="exclamation" />
            <div className="content">
              若輸入之關鍵字<b>過長</b>或包含<b>特殊字元</b>
              ，容易造成搜尋錯誤，建議擷取書名開頭搜尋以及避開<b>特殊字元</b>。
            </div>
          </TooLongWarning>
        ) : null}
        <div style={{ margin: '0 auto' }}>
          <PlatformTag>
            <CityCheckbox
              setSelectedCities={this.setSelectedCities}
              cities={cities}
            />
          </PlatformTag>
        </div>
        {noCityWarning ? (
          <NoCityWarning>
            <Icon name="exclamation" />
            <div className="content">請至少選擇一個圖書館。</div>
          </NoCityWarning>
        ) : null}

        <NavBar
          updateLanguage={this.updateLanguage}
          setRankList={this.setRankList}
          setRankListVisible={this.setRankListVisible}
          rankListVisible={rankListVisible}
          setSubRankListVisible={this.setSubRankListVisible}
          subRankListVisible={subRankListVisible}
        />
        {this.displayContent()}

        <HeadwayWidget />
      </Home>
    );
  }
}

const PlatformTag = styled.div`
  display: inline-block;
  border: 2px solid rgba(0, 0, 0, 0.2);
  border-radius: 56px;
  padding: 0.75rem;
  margin: 1rem 1rem 0;

  @media (max-width: 320px) {
    font-size: 12px;
    padding: 0 0.7rem;
    line-height: 32px;
  }
`;

const SearchInput = styled.input`
  width: 18rem;
  height: 35px;
  padding: 0.25rem 1rem;
  border: 2px solid ${colors.mainGreen};
  border-radius: 0;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  &:focus-visible {
    outline: none !important;
  }

  @media (max-width: 320px) {
    width: 12rem;
  }
`;

const SearchButton = styled.button`
  width: 6rem;
  height: 35px;
  border: 0;
  background-color: ${colors.mainGreen};
  border: 2px solid ${colors.mainGreen};
  color: white;
  transform: translateX(-1px);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  opacity: ${(props) => (props.disable ? '0.8' : '1')};
  cursor: pointer;
`;

const Home = styled.div`
  padding-top: 5rem;

  @media (max-width: 375px) {
    padding-top: 6rem;
  }
`;

const TooLongWarning = styled.div`
  background-color: #fffaf3;
  color: #573a08;
  box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent;
  padding: 0.3rem;
  color: rgba(0, 0, 0, 0.87);
  width: 28rem;
  margin: 1rem auto 0;

  .icon {
    margin-right: 0.2em;
    line-height: 1;
    font-size: 1.5em;
    opacity: 0.8;
    display: inline-block;
    vertical-align: middle;
  }

  .content {
    text-align: left;
    display: inline-block;
    vertical-align: middle;
    width: 24.5rem;
  }
`;

const NoCityWarning = styled.div`
  background-color: #fffaf3;
  color: #573a08;
  box-shadow: 0 0 0 1px #c9ba9b inset, 0 0 0 0 transparent;
  padding: 0.3rem;
  color: rgba(0, 0, 0, 0.87);
  width: 16rem;
  margin: 1rem auto 0;

  .icon {
    margin-right: 0.2em;
    line-height: 1;
    font-size: 1.5em;
    opacity: 0.8;
    display: inline-block;
    vertical-align: middle;
  }

  .content {
    text-align: left;
    display: inline-block;
    vertical-align: middle;
  }
`;

const TurnOffAdBlocker = styled.button`
  width: 185px;
  height: 35px;
  border: 0;
  background-color: ${colors.mainGreen};
  border: 2px solid ${colors.mainGreen};
  color: white;
  transform: translateX(-1px);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  cursor: not-allowed;
`;
