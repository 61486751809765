export function getUrls(search) {
  search = search.toLowerCase();
  search = search.replace(' ', '+');
  search = search.replace('#', '%23');
  search = search.replace('%', '%25');
  search = search.replace('[', '%5B');
  search = search.replace(']', '%5D');

  const urls = {
    'Taiwan hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://ntledu.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://ntledu.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Taipei hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://tpml.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://tpml.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'New Taipei City hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://tphcc.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://tphcc.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Hsinchu hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://hcmlgov.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://hcmlgov.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Hsinchu County hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://hchcc.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://hchcc.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Taichung hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://taichunggov.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://taichunggov.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Keelung hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://klccab.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://klccab.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'National hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://ncl.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://ncl.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Public Information hyread':
      'https://customcorsanywhere.herokuapp.com/https://ebook.nlpi.edu.tw/search/resultdata?search_field=TI&search_input=' +
      search,
    'Taoyuan hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://tycccgov.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://tycccgov.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Miaoli hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://mlcg.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://mlcg.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Miaoli County hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://miaolilib.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://miaolilib.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Changhua hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://chcedu.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://chcedu.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Chiayi hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://cabcygov.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://cabcygov.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Tainan hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://tnml.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://tnml.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Kaohsiung hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://ksml.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://ksml.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Yilan hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://ilccb.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://ilccb.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Hualien hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://hccc.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://hccc.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Taidong hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://cclttct.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://cclttct.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Penghu hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://phhcc.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://phhcc.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Golden hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://kinmen.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://kinmen.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,
    'Lienchiang hyread': search.startsWith('python')
      ? 'https://customcorsanywhere.herokuapp.com/https://matsucc.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=python'
      : 'https://customcorsanywhere.herokuapp.com/https://matsucc.ebook.hyread.com.tw/searchList.jsp?search_field=FullText&search_input=' +
        search,

    'Taiwan udn':
      'https://reading.udn.com/udnLibService/searchBookData/cwktrial/B?kw=' +
      search +
      '&page=1&amount=10&opt=all&orderby=name&type=asc',
    'Taipei udn':
      'https://reading.udn.com/udnLibService/searchBookData/tpml/B?kw=' +
      search +
      '&page=1&amount=10&opt=all&orderby=name&type=asc',
    'New Taipei City udn':
      'https://reading.udn.com/udnLibService/searchBookData/tpc/B?kw=' +
      search +
      '&page=1&amount=10&opt=all&orderby=name&type=asc',
    'Taichung udn':
      'https://reading.udn.com/udnLibService/searchBookData/taich/B?kw=' +
      search +
      '&page=1&amount=10&opt=all&orderby=name&type=asc',
    'Keelung udn':
      'https://reading.udn.com/udnLibService/searchBookData/klccab/B?kw=' +
      search +
      '&page=1&amount=10&opt=all&orderby=name&type=asc',
    'National udn':
      'https://reading.udn.com/udnLibService/searchBookData/ncl/B?kw=' +
      search +
      '&page=1&amount=10&opt=all&orderby=name&type=asc',
    'Public Information udn':
      'https://reading.udn.com/udnLibService/searchBookData/ntl/B?kw=' +
      search +
      '&page=1&amount=10&opt=all&orderby=name&type=asc',
    'Taoyuan udn':
      'https://reading.udn.com/udnLibService/searchBookData/tyccc/B?kw=' +
      search +
      '&page=1&amount=10&opt=all&orderby=name&type=asc',
    'Miaoli County udn':
      'https://reading.udn.com/udnLibService/searchBookData/miaoli/B?kw=' +
      search +
      '&page=1&amount=10&opt=all&orderby=name&type=asc',
    'Chiayi udn':
      'https://reading.udn.com/udnLibService/searchBookData/cabcy/B?kw=' +
      search +
      '&page=1&amount=10&opt=all&orderby=name&type=asc',
    'Kaohsiung udn':
      'https://reading.udn.com/udnLibService/searchBookData/ksm/B?kw=' +
      search +
      '&page=1&amount=10&opt=all&orderby=name&type=asc',
    'Yilan udn':
      'https://reading.udn.com/udnLibService/searchBookData/ilccb/B?kw=' +
      search +
      '&page=1&amount=10&opt=all&orderby=name&type=asc',
    'Hualien udn':
      'https://reading.udn.com/udnLibService/searchBookData/hlshcc/B?kw=' +
      search +
      '&page=1&amount=10&opt=all&orderby=name&type=asc',
    'Taidong udn':
      'https://reading.udn.com/udnLibService/searchBookData/ttct/B?kw=' +
      search +
      '&page=1&amount=10&opt=all&orderby=name&type=asc',
    'Penghu udn':
      'https://reading.udn.com/udnLibService/searchBookData/phhcc/B?kw=' +
      search +
      '&page=1&amount=10&opt=all&orderby=name&type=asc',

    'Taiwan cloud':
      'https://m.ebookservice.tw/api/3.01/ntl2/Search/?keyword=' +
      search +
      '&scope=2&pageSize=24&pageNo=1',
    'Taipei cloud':
      'https://m.ebookservice.tw/api/3.01/taipei/Search/?keyword=' +
      search +
      '&scope=2&pageSize=24&pageNo=1',
    'New Taipei City cloud':
      'https://m.ebookservice.tw/api/3.01/nt/Search/?keyword=' +
      search +
      '&scope=2&pageSize=24&pageNo=1',
    'Hsinchu cloud':
      'https://m.ebookservice.tw/api/3.01/hc/Search/?keyword=' +
      search +
      '&scope=2&pageSize=24&pageNo=1',

    'Taiwan iread':
      'https://customcorsanywhere.herokuapp.com/https://www.airitibooks.com/Search/Results?SearchFieldList_obj=%5B%7B%22SearchString%22%3A%22' +
      search +
      '%22%2C%22SearchType%22%3A%22%25E6%2589%2580%25E6%259C%2589%25E6%25AC%2584%25E4%25BD%258D%22%2C%22SearchFieldCondition%22%3A%22AND%22%7D%5D&OutputKeyinSearchFieldList_obj=%5B%7B%22SearchString%22%3A%22' +
      search +
      '%22%2C%22SearchType%22%3A%22%25E6%2589%2580%25E6%259C%2589%25E6%25AC%2584%25E4%25BD%258D%22%2C%22SearchFieldCondition%22%3A%22AND%22%7D%5D&IsLibraryCollections=Y&toPage=',
  };

  return urls;
}

export function getHyReadLinkedUrls(search) {
  const urls = {
    'Taiwan hyread': 'https://ntledu.ebook.hyread.com.tw/',
    'Taipei hyread': 'https://tpml.ebook.hyread.com.tw/',
    'New Taipei City hyread': 'https://tphcc.ebook.hyread.com.tw/',
    'Hsinchu hyread': 'https://hcmlgov.ebook.hyread.com.tw/',
    'Hsinchu County hyread': 'https://hchcc.ebook.hyread.com.tw/',
    'Taichung hyread': 'https://taichunggov.ebook.hyread.com.tw/',
    'Keelung hyread': 'https://klccab.ebook.hyread.com.tw/',
    'National hyread': 'https://ncl.ebook.hyread.com.tw/',
    'Public Information hyread':
      'https://ebook.nlpi.edu.tw/search?search_field=TI&search_input=' + search,
    'Taoyuan hyread': 'https://tycccgov.ebook.hyread.com.tw/',
    'Miaoli hyread': 'https://mlcg.ebook.hyread.com.tw/',
    'Miaoli County hyread': 'https://miaolilib.ebook.hyread.com.tw/',
    'Changhua hyread': 'https://chcedu.ebook.hyread.com.tw/',
    'Chiayi hyread': 'https://cabcygov.ebook.hyread.com.tw/',
    'Tainan hyread': 'https://tnml.ebook.hyread.com.tw/',
    'Kaohsiung hyread': 'https://ksml.ebook.hyread.com.tw/',
    'Yilan hyread': 'https://ilccb.ebook.hyread.com.tw/',
    'Hualien hyread': 'https://hccc.ebook.hyread.com.tw/',
    'Taidong hyread': 'https://cclttct.ebook.hyread.com.tw/',
    'Penghu hyread': 'https://phhcc.ebook.hyread.com.tw/',
    'Golden hyread': 'https://kinmen.ebook.hyread.com.tw/',
    'Lienchiang hyread': 'https://matsucc.ebook.hyread.com.tw/',
  };

  return urls;
}

export function getUdnLinkedUrls(search) {
  const urls = {
    'Taiwan udn':
      'https://reading.udn.com/udnlib/cwktrial/booksearch?sort=all&opt=all&kw=' +
      search,
    'Taipei udn':
      'https://reading.udn.com/udnlib/tpml/booksearch?sort=all&opt=all&kw=' +
      search,
    'New Taipei City udn':
      'https://reading.udn.com/udnlib/tpc/booksearch?sort=all&opt=all&kw=' +
      search,
    'Taichung udn':
      'https://reading.udn.com/udnlib/taich/booksearch?sort=all&opt=all&kw=' +
      search,
    'Keelung udn':
      'https://reading.udn.com/udnlib/klccab/booksearch?sort=all&opt=all&kw=' +
      search,
    'National udn':
      'https://reading.udn.com/udnlib/ncl/booksearch?sort=all&opt=all&kw=' +
      search,
    'Public Information udn':
      'https://reading.udn.com/udnlib/ntl/booksearch?sort=all&opt=all&kw=' +
      search,
    'Taoyuan udn':
      'https://reading.udn.com/udnlib/tyccc/booksearch?sort=all&opt=all&kw=' +
      search,
    'Miaoli County udn':
      'https://reading.udn.com/udnlib/miaoli/booksearch?sort=all&opt=all&kw=' +
      search,
    'Chiayi udn':
      'https://reading.udn.com/udnlib/cabcy/booksearch?sort=all&opt=all&kw=' +
      search,
    'Kaohsiung udn':
      'https://reading.udn.com/udnlib/ksm/booksearch?sort=all&opt=all&kw=' +
      search,
    'Yilan udn':
      'https://reading.udn.com/udnlib/ilccb/booksearch?sort=all&opt=all&kw=' +
      search,
    'Hualien udn':
      'https://reading.udn.com/udnlib/hlshcc/booksearch?sort=all&opt=all&kw=' +
      search,
    'Taidong udn':
      'https://reading.udn.com/udnlib/ttct/booksearch?sort=all&opt=all&kw=' +
      search,
    'Penghu udn':
      'https://reading.udn.com/udnlib/phhcc/booksearch?sort=all&opt=all&kw=' +
      search,
  };

  return urls;
}

export function getCloudLinkedUrls(search) {
  const urls = {
    'Taiwan cloud': 'https://lib.ebookservice.tw/ntl2/#search/' + search,
    'Taipei cloud': 'https://lib.ebookservice.tw/tp/#search/' + search,
    'New Taipei City cloud': 'https://lib.ebookservice.tw/nt/#search/' + search,
    'Hsinchu cloud': 'https://lib.ebookservice.tw/hc/#search/' + search,
  };

  return urls;
}

export function getIreadLinkedUrls(search) {
  const urls = {
    'Taiwan iread':
      'https://customcorsanywhere.herokuapp.com/https://www.airitibooks.com/Search/Results?SearchFieldList_obj=%5B%7B%22SearchString%22%3A%22' +
      search +
      '%22%2C%22SearchType%22%3A%22%25E6%2589%2580%25E6%259C%2589%25E6%25AC%2584%25E4%25BD%258D%22%2C%22SearchFieldCondition%22%3A%22AND%22%7D%5D&OutputKeyinSearchFieldList_obj=%5B%7B%22SearchString%22%3A%22' +
      search +
      '%22%2C%22SearchType%22%3A%22%25E6%2589%2580%25E6%259C%2589%25E6%25AC%2584%25E4%25BD%258D%22%2C%22SearchFieldCondition%22%3A%22AND%22%7D%5D&IsLibraryCollections=Y&toPage=',
  };

  return urls;
}

export function purifyBookName(str) {
  str = ToCDB(str);
  str = str.split(' ').join('');

  str = str.replace(
    /[`「」、《》【】!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/gi,
    ''
  );

  return str;
}

export function getHyreadRankUrls(category) {
  const urls = {
    bestSelling: 'https://ebook.hyread.com.tw/Template/store/bestSelling.jsp',
    rentalRating: 'https://ebook.hyread.com.tw/Template/store/rentalRating.jsp',
    magazineRating:
      'https://ebook.hyread.com.tw/Template/store/magazineRating.jsp',
    trialRating: 'https://ebook.hyread.com.tw/Template/store/trialRating.jsp',
  };

  return urls[category];
}

export function getReadmooRankUrls(category) {
  const urls = {
    instant:
      'https://young-chamber-65012.herokuapp.com/https://readmoo.com/leaderboard/book/instant/',
    sale: 'https://young-chamber-65012.herokuapp.com/https://readmoo.com/leaderboard/book/sale/',
    reading:
      'https://young-chamber-65012.herokuapp.com/https://readmoo.com/leaderboard/book/reading/',
  };

  return urls[category];
}

const purifyHttpsResponse = (str) => {
  str = str.replace(/(?:\\[rnt])+/g, '');
  str = str.replace(/(?:\\["])+/g, '"');

  return str;
};

export function parseHyreadRankList(str) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  const bookNames = doc.body.getElementsByClassName('book-title-01');
  let bookNameList = [];

  for (let i = 0; i < bookNames.length; i++) {
    bookNameList.push(bookNames[i].textContent);
  }

  return bookNameList;
}

export function parseReadmooRankList(str) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  const bookNames = doc.body.querySelectorAll('.cover-img');
  let bookNameList = [];

  for (let i = 0; i < bookNames.length; i++) {
    bookNameList.push(bookNames[i].alt);
  }

  return bookNameList;
}

export function parseBerkeleyRankList(str) {
  const parser = new DOMParser();
  const doc = parser.parseFromString(str, 'text/html');
  const bookNames = doc.body.querySelectorAll('.type02_bd-a > h4');
  let bookNameList = [];

  for (let i = 0; i < bookNames.length; i++) {
    bookNameList.push(bookNames[i].textContent);
  }

  return bookNameList;
}

function ToCDB(str) {
  var tmp = '';
  for (var i = 0; i < str.length; i++) {
    if (str.charCodeAt(i) == 12288) {
      tmp += String.fromCharCode(str.charCodeAt(i) - 12256);
      continue;
    }
    if (str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375) {
      tmp += String.fromCharCode(str.charCodeAt(i) - 65248);
    } else {
      tmp += String.fromCharCode(str.charCodeAt(i));
    }
  }
  return tmp;
}

export function cityTranslate() {
  return {
    國立臺灣圖書館: {
      hyread: 'Taiwan hyread',
      udn: 'Taiwan udn',
      cloud: 'Taiwan cloud',
      // iread: 'Taiwan iread',
    },
    基隆市文化局: {
      hyread: 'Keelung hyread',
      udn: 'Keelung udn',
    },
    臺北市立圖書館: {
      hyread: 'Taipei hyread',
      udn: 'Taipei udn',
    },
    新北市立圖書館: {
      hyread: 'New Taipei City hyread',
      udn: 'New Taipei City udn',
    },
    臺中市立圖書館: {
      hyread: 'Taichung hyread',
      udn: 'Taichung udn',
    },
    桃園市立圖書館: {
      hyread: 'Taoyuan hyread',
      udn: 'Taoyuan udn',
    },
    新竹市文化局圖書館: {
      hyread: 'Hsinchu hyread',
      udn: 'Hsinchu udn',
    },
    新竹縣政府文化局: {
      hyread: 'Hsinchu County hyread',
      udn: 'Hsinchu County udn',
    },
    苗栗市立圖書館: {
      hyread: 'Miaoli hyread',
    },
    苗栗縣立圖書館: {
      hyread: 'Miaoli County hyread',
      udn: 'Miaoli County udn',
    },
    彰化雲端電子書庫: {
      hyread: 'Changhua hyread',
      udn: 'Changhua udn',
    },
    嘉義市政府文化局: {
      hyread: 'Chiayi hyread',
      udn: 'Chiayi udn',
    },
    臺南市立圖書館: {
      hyread: 'Tainan hyread',
      udn: 'Tainan udn',
    },
    高雄市立圖書館: {
      hyread: 'Kaohsiung hyread',
      udn: 'Kaohsiung udn',
    },
    宜蘭縣政府文化局: {
      hyread: 'Yilan hyread',
      udn: 'Yilan udn',
    },
    花蓮縣文化局: {
      hyread: 'Hualien hyread',
      udn: 'Hualien udn',
    },
    臺東縣政府文化處: {
      hyread: 'Taidong hyread',
      udn: 'Taidong udn',
    },
    澎湖縣圖書館: {
      hyread: 'Penghu hyread',
      udn: 'Penghu udn',
    },
    金門縣文化局: {
      hyread: 'Golden hyread',
      udn: 'Golden udn',
    },
    連江縣公共圖書館: {
      hyread: 'Lienchiang hyread',
      udn: 'Lienchiang udn',
    },
  };
}
