import React from 'react';
import styled from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';
import MultiLangText from './MultiLangText';
import buyMeCoffee from './images/bmc.png';

const BuyMeCoffee = ({}) => {
  return (
    <DonateContainer>
      <div style={{ textAlign: 'left', fontSize: '15px' }}>
        謝謝您使用「台灣圖書館電子書搜尋」，如果這網站對您有幫助，讓您順利找到書籍，
        歡迎&nbsp;
        <a
          href="https://www.buymeacoffee.com/twlibraryebook/membership"
          target="_blank"
        >
          加入會員
        </a>
        &nbsp;
        (每個月65塊台幣)支持開發者。您的支持讓我能繼續維護網站運作，提供更好的使用者體驗，謝謝！
      </div>

      <br />
      {/* <div style={{ opacity: '0.7', fontSize: '12px' }}>
        ∗<MultiLangText text="donate" />∗
      </div> */}
    </DonateContainer>
  );
};

export default BuyMeCoffee;

const DonateContainer = styled.div`
  width: 27rem;
  height: 8rem;
  background: rgba(0, 0, 0, 0.05);
  padding: 1rem;
  border-radius: 10px;
  display: inline-block;

  @media (max-width: 320px) {
    width: 20rem;
  }
`;
