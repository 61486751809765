import React, { Component } from 'react';
import MultiLangText from './MultiLangText';
import styled from 'styled-components';
import './i18n';
import books from './static/books.json';
import berkeleyThirtyDays from './static/berkeleyThirtyDays.json';
import berkeleyYearly from './static/berkeleyYearly.json';
import {
  getHyreadRankUrls,
  getBooksYearlyRankList,
  getReadmooRankUrls,
  purifyBookName,
  parseHyreadRankList,
  parseBerkeleyRankList,
  parseReadmooRankList,
} from './utils/helpers';
import Loading from './loading';
import Image from './Image';

const rankName = {
  hyread_bestSelling: 'HyRead 暢銷排行',
  hyread_rentalRating: 'HyRead 租書排行',
  hyread_magazineRating: 'HyRead 雜誌排行',
  hyread_trialRating: 'HyRead 試閱排行',
  berkeley_thirtyDays: '博客來 30日排行',
  berkeley_yearly: '博客來 2020年度排行',
  readmoo_instant: '讀墨 綜合榜',
  readmoo_sale: '讀墨 暢銷榜',
  readmoo_reading: '讀墨 閱讀榜',
};

const rankUrls = {
  hyread_bestSelling:
    'https://ebook.hyread.com.tw/Template/store/bestSelling.jsp',
  hyread_rentalRating:
    'https://ebook.hyread.com.tw/Template/store/rentalRating.jsp',
  hyread_magazineRating:
    'https://ebook.hyread.com.tw/Template/store/magazineRating.jsp',
  hyread_trialRating:
    'https://ebook.hyread.com.tw/Template/store/trialRating.jsp',
  berkeley_thirtyDays:
    'https://www.books.com.tw/web/sys_saletopb/books/?attribute=30',
  berkeley_yearly: 'https://www.books.com.tw/web/annual100/',
  readmoo_instant: 'https://readmoo.com/leaderboard/book/instant/',
  readmoo_sale: 'https://readmoo.com/leaderboard/book/sale/',
  readmoo_reading: 'https://readmoo.com/leaderboard/book/reading/',
};

export default class RankSearch extends Component {
  state = { loading: false, findBookList: [], platform: null, category: null };

  componentDidMount = () => {
    const { subRankListVisible, rankList } = this.props;

    this.getBookList();
    this.setState({
      platform: subRankListVisible,
      category: rankList,
    });
  };

  componentDidUpdate = (prevProps) => {
    if (prevProps.rankList !== this.props.rankList) {
      this.getBookList();
    }
  };

  async sendRequest(url, parseFunction) {
    try {
      const { setRankListVisible, setSubRankListVisible } = this.props;
      this.setState({ loading: true });

      const response = await fetch(url);
      const responseToText = await response.text();
      const bookNameList = new Set(
        parseFunction(responseToText).map((book) => purifyBookName(book))
      );
      const findBookList = books.list.reduce((result, book) => {
        if (bookNameList.has(purifyBookName(book.book_name))) {
          result.push({
            book_name: book.book_name,
            image: book.image,
          });
        }
        return result;
      }, []);

      this.setState({ loading: false, findBookList });
      setRankListVisible(false);
      setSubRankListVisible(false);
    } catch (err) {
      console.log(err);
    }
  }

  getBookList = () => {
    const { rankList, subRankListVisible } = this.props;

    if (subRankListVisible === 'hyread') {
      const url = getHyreadRankUrls(rankList);

      this.sendRequest(url, parseHyreadRankList);
    } else if (subRankListVisible === 'berkeley') {
      if (rankList === 'thirtyDays') {
        const url =
          'https://young-chamber-65012.herokuapp.com/https://www.books.com.tw/web/sys_saletopb/books/?attribute=30';

        return this.sendRequest(url, parseBerkeleyRankList);
      }

      const { setRankListVisible, setSubRankListVisible } = this.props;
      const bookNameList = new Set(
        berkeleyYearly.list.map((book) => purifyBookName(book))
      );
      const findBookList = books.list.reduce((result, book) => {
        if (bookNameList.has(purifyBookName(book.book_name))) {
          result.push({
            book_name: book.book_name,
            image: book.image,
          });
        }
        return result;
      }, []);

      this.setState({ findBookList });
      setRankListVisible(false);
      setSubRankListVisible(false);
    } else if (subRankListVisible === 'readmoo') {
      const url = getReadmooRankUrls(rankList);

      this.sendRequest(url, parseReadmooRankList);
    }
  };

  generateBookList = () => {
    const { findBookList, platform, category } = this.state;
    const { setSearchFirstThenSubmit } = this.props;

    return findBookList && findBookList.length ? (
      <div style={{ padding: '1rem 0' }}>
        <h3>
          這些在&nbsp;
          <a href={rankUrls[`${platform}_${category}`]} target="_blank">
            {rankName[`${platform}_${category}`]}
          </a>
          &nbsp;的書，可以在圖書館找到！
        </h3>
        {findBookList.map((book, index) => (
          <RankItem key={index}>
            <Image
              src={book.image}
              fallbackSrc="https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"
              onClick={() =>
                setSearchFirstThenSubmit(book.book_name.substring(0, 8))
              }
              style={{ cursor: 'pointer' }}
            />
            <br />
            <p>
              <b>{book.book_name}</b>
            </p>
          </RankItem>
        ))}
      </div>
    ) : (
      <div style={{ padding: '1rem 0' }}>
        <h3>沒有找到。</h3>
      </div>
    );
  };

  render() {
    const { loading } = this.state;

    return loading ? (
      <Loading active />
    ) : (
      <ListContainer>{this.generateBookList()}</ListContainer>
    );
  }
}

const ListContainer = styled.div`
  margin: 1rem auto;
  max-width: 60rem;

  @media (max-width: 375px) {
    width: 95%;
  }
`;

const RankItem = styled.div`
  display: inline-block;
  vertical-align: top;
  margin: 1rem;

  p {
    max-width: 16rem;
    margin: 0 auto;

    @media (max-width: 375px) {
      max-width: 10rem;
    }

    @media (max-width: 320px) {
      max-width: 8rem;
    }
  }

  img {
    width: 16rem;

    @media (max-width: 375px) {
      width: 10rem;
    }

    @media (max-width: 320px) {
      max-width: 8rem;
    }
  }
`;
