import React from 'react';
import styled from 'styled-components';

const CloseIcon = ({ close }) => <Close onClick={() => close()}>+</Close>;

export default CloseIcon;

const Close = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
  height: 20px;
  width: 20px;
  text-align: center;
  font-size: 1.7rem;
  border-radius: 50%;
  line-height: 16px;
  transform: rotate(45deg);
  transition: all 0.1s ease;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.8);
    color: #fff6f6;
  }
`;
