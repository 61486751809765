import React from 'react';
import styled from 'styled-components';
import { Icon, Popup } from 'semantic-ui-react';
import MultiLangText from './MultiLangText';
import backgroundImg from './images/library.jpg';
import {
  getUrls,
  getHyReadLinkedUrls,
  getUdnLinkedUrls,
  getCloudLinkedUrls,
  getIreadLinkedUrls,
} from './utils/helpers';
import { colors } from './utils/colors';

const Result = ({ allLibraryResult, search }) => {
  if (!Object.keys(allLibraryResult).length) return null;

  const udn = Object.keys(allLibraryResult).filter((key) =>
    key.includes('udn')
  );
  const hyread = Object.keys(allLibraryResult).filter((key) =>
    key.includes('hyread')
  );
  const cloud = Object.keys(allLibraryResult).filter((key) =>
    key.includes('cloud')
  );
  const iread = Object.keys(allLibraryResult).filter((key) =>
    key.includes('iread')
  );

  const limitBookNameLength = (str) => {
    const specialCharacters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

    let limit = 25;

    if (specialCharacters.test(str)) {
      limit = 23;
    }

    return str.length > limit ? `${str.substring(0, limit)}...` : str;
  };

  const generateLibraries = (list, platform) => {
    if (!list.find((l) => allLibraryResult[l] !== 'N/A')) {
      return (
        <div style={{ marginTop: '1rem', fontSize: '16px' }}>
          找不到符合的書本或雜誌。
        </div>
      );
    }

    const displayResultAmount =
      platform === 'platformCloud' || platform === 'platformIread' ? 10 : 5;
    const urls =
      platform === 'platformUdn'
        ? getUdnLinkedUrls(search)
        : platform === 'platformHyread'
        ? getHyReadLinkedUrls(search)
        : platform === 'platformCloud'
        ? getCloudLinkedUrls(search)
        : platform === 'platformIread'
        ? getIreadLinkedUrls(search)
        : null;

    const generateBookList = (lib, bList) => {
      return !bList ? null : bList.length > displayResultAmount ? (
        <ul className="items-list">
          {bList.slice(0, displayResultAmount).map((book, index) => (
            <li key={index}>
              {platform === 'platformUdn'
                ? limitBookNameLength(book.bookname)
                : platform === 'platformCloud'
                ? limitBookNameLength(
                    book.highlights.title
                      .split('*#*')
                      .join('')
                      .split('*%*')
                      .join('')
                  )
                : limitBookNameLength(book.trim())}
              。
            </li>
          ))}
          <div>
            <MultiLangText text="more" />
            <span style={{ display: 'inline-block' }}>...</span>
          </div>
        </ul>
      ) : (
        bList.map((book, index) => (
          <ul className="items-list" key={index + 1}>
            <li key={index + 2}>
              {platform === 'platformUdn'
                ? limitBookNameLength(book.bookname)
                : platform === 'platformCloud'
                ? limitBookNameLength(
                    book.highlights.title
                      .split('*#*')
                      .join('')
                      .split('*%*')
                      .join('')
                  )
                : limitBookNameLength(book.trim())}
              。
            </li>
          </ul>
        ))
      );
    };

    return list.map((library, index) => {
      const formatResults = () => {
        if (platform === 'platformUdn') {
          const bookList = JSON.parse(allLibraryResult[library]).data.booklist;

          return generateBookList(library, bookList);
        } else if (platform === 'platformCloud') {
          const bookList = JSON.parse(allLibraryResult[library]).hits.hit;

          return generateBookList(library, bookList);
        }
        //  else if (platform === 'platformIread') {
        //   if (
        //     allLibraryResult[library].includes(
        //       '../Detail/Detail?PublicationID='
        //     )
        //   ) {
        //     return (
        //       <a href={getIreadLinkedUrls(search)[library]} target="_blank">
        //         <br />
        //         <MultiLangText text="checkOnIread" />
        //       </a>
        //     );
        //   }
        // }
        else if (library === 'Public Information hyread') {
          const bookList = JSON.parse(allLibraryResult[library]).record.map(
            (book) => book.t
          );

          return generateBookList(library, bookList);
        }

        const getBookList = (str, cName) => {
          let books = {};
          const parser = new DOMParser();
          const doc = parser.parseFromString(str, 'text/html');
          const bookNames = doc.body.getElementsByClassName(cName);
          for (let i = 0; i < bookNames.length; i++) {
            books[bookNames[i].textContent] = true;
          }

          return Object.keys(books);
        };
        const searchClassName =
          platform === 'platformIread' ? 'bookname' : 'owl-title';
        const bookList = getBookList(
          allLibraryResult[library],
          searchClassName
        );

        return generateBookList(library, bookList);
      };

      let libraryName = null;

      // Put hyread, udn, or cloud at the end to distinguish platforms
      libraryName = library.split(' ').slice(0, -1).join(' ');

      if (allLibraryResult[library] === 'N/A') return null;

      return (
        <div>
          <span>
            <h3 className="span_header">
              <a href={urls[library]} target="_blank">
                <MultiLangText text={libraryName} />
                {platform === 'platformCloud' ? (
                  <Popup
                    hoverable
                    trigger={
                      <Icon
                        name="question circle outline"
                        style={{ fontSize: '16px', color: 'dimgrey' }}
                      />
                    }
                    position="top center"
                    wide
                  >
                    <MultiLangText text="Same_result" />
                  </Popup>
                ) : null}
                <i class="arrow alternate circle right outline icon"></i>
              </a>
            </h3>
          </span>
          <span style={{ fontSize: '16px' }}>
            {formatResults(allLibraryResult[library])}
          </span>
        </div>
      );
    });
  };

  return (
    <ResultContainer>
      <div
        style={{
          margin: '1rem auto 0',
          fontSize: '1.2rem',
          padding: '1rem 0 0',
        }}
      >
        <StyledH3
          style={{
            margin: '0',
            paddingBottom: '1rem',
            position: 'relative',
          }}
        >
          搜尋結果
        </StyledH3>
        <div
          style={{
            width: '92%',
            margin: '0 auto',
            borderTop: '1px solid rgba(0,0,0,0.3)',
          }}
        >
          <StyledBlock>
            <div className="items">
              <div className="items-head">
                <p>HYREAD</p>
                <hr />
              </div>
              <div className="items-body">
                <div className="items-body-content">
                  {generateLibraries(hyread, 'platformHyread')}
                </div>
              </div>
            </div>
          </StyledBlock>
          {/* <p style={{ marginTop: '1rem' }}>
              因HyRead更改搜尋格式，暫無法於本站提供搜尋結果，
              <br />
              建議可先前往
              <a href="https://one.ebook.hyread.com.tw/" target="_blank">
                HyRead One
              </a>
              搜尋。
            </p> */}
          <StyledBlock>
            <div className="items">
              <div className="items-head">
                <p>UDN</p>
                <hr />
              </div>
              <div className="items-body">
                <div className="items-body-content">
                  {generateLibraries(udn, 'platformUdn')}
                </div>
              </div>
            </div>
          </StyledBlock>
          <StyledBlock>
            <div className="items">
              <div className="items-head">
                <p>台灣雲端書庫</p>
                <hr />
              </div>
              <div className="items-body">
                <div className="items-body-content">
                  {generateLibraries(cloud, 'platformCloud')}
                </div>
              </div>
            </div>
          </StyledBlock>
          {/* <StyledBlock>
            <div className="items">
              <div className="items-head">
                <p>華藝電子書</p>
                <hr />
              </div>
              <div className="items-body">
                <div className="items-body-content">
                  {generateLibraries(iread, 'platformIread')}
                </div>
                <p style={{ marginTop: '1rem' }}>
                  暫無法於本站提供搜尋結果，
                  <br />
                  建議可先前往
                  <a href="https://www.airitibooks.com" target="_blank">
                    華藝電子書
                  </a>
                  搜尋。
                </p>
              </div>
            </div>
          </StyledBlock> */}
        </div>
      </div>
    </ResultContainer>
  );
};

export default Result;

const ResultContainer = styled.div`
  width: 100%;
  height: 100%;
  display: block;
  position: relative;

  /*
  &::after {
    content: '';
    background: url(${backgroundImg});
    opacity: 0.05;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: absolute;
    z-index: -1;
  }
*/
`;

const StyledBlock = styled.div`
  width: 45%;
  display: inline-block;
  margin: 2rem;
  vertical-align: top;

  @media (max-width: 1280px) {
    width: 40%;
  }

  @media (max-width: 800px) {
    width: 80%;
  }

  .items {
    background: #fffffe;
    box-shadow: 0 3px 6px rgb(0 0 0 / 16%), 0 3px 6px rgb(0 0 0 / 23%);
    border-top: 15px solid ${colors.mainGreen};
    border-radius: 8px;
    text-align: left;
  }

  .items-head p {
    padding: 5px 20px;
    margin: 10px;
    color: ${colors.mainGreen};
    font-weight: bold;
    font-size: 20px;
  }

  .items-head hr {
    width: 20%;
    margin: 0px 30px;
    border: 1px solid ${colors.mainGreen};
  }

  .items-body {
    padding: 10px;
    margin: 10px;
    padding-top: 5px;
    margin-top: 5px;
  }

  .items-body-content {
    margin: 10px;
    margin-right: 0px;
    font-size: 13px;
    border: 1px solid transparent;
  }

  .items-body-content i {
    align-self: center;
    font-size: 15px;
    color: ${colors.mainGreen};
    font-weight: bold;
    animation: icon 1.5s infinite forwards;
  }
  /* 
  @keyframes icon {
    0%,
    100% {
      transform: translate(0px);
    }
    50% {
      transform: translate(3px);
    }
  } */

  .items-list li::marker {
    content: '‣  ';
    font-size: 24px;
    color: ${colors.mainGreen};
    padding-right: 10px;
  }

  .span_header {
    display: inline-block;
    margin: 0 !important;
  }
`;

const StyledH3 = styled.h3`
  display: inline-block;

  &:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 3px;
    background: ${colors.mainGreen};
    border-radius: 8px;
  }
`;
