import React, { Component } from 'react';
import styled from 'styled-components';

const HW_CONFIG_ACCOUNT = 'xDAd47';
const ELEMENT_ID = 'headway-updates-widget';

class HeadwayWidget extends Component {
  initWidget = (element) => {
    if (element) {
      Headway.init({
        selector: `#${ELEMENT_ID}`,
        account: HW_CONFIG_ACCOUNT,
      });
    }
  };

  render() {
    return (
      <StyledBadget>
        <div id={ELEMENT_ID} ref={this.initWidget} />
      </StyledBadget>
    );
  }
}

export default HeadwayWidget;

const StyledBadget = styled.div`
  position: fixed;
  bottom: 2.5rem;
  right: 1rem;

  .HW_badge {
    width: 32px;
    height: 32px;
    border-radius: 40px;
    font-size: 16px;
    line-height: 32px;
  }
`;
