import React, { Suspense, useState, useEffect } from 'react';
import './App.css';
import Library from './library';
import { useDetectAdBlock } from 'adblock-detect-react';

import AdblockModal from './AdblockModal';

function App() {
  const adBlockDetected = useDetectAdBlock();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (adBlockDetected) {
      setOpen(true);
    }
  });

  return (
    <div className="App" style={{ padding: '1rem' }}>
      <Suspense fallback={<div>Loading...</div>}>
        <Library adBlockDetected={adBlockDetected} />
      </Suspense>
      <AdblockModal open={open} />
    </div>
  );
}

export default App;
