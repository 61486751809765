import React from 'react';
import { Dimmer, Loader } from 'semantic-ui-react';
import MultiLangText from './MultiLangText';

const Loading = ({ active, fixed }) => (
  <Dimmer
    active={active}
    inverted
    style={{ zIndex: 100, position: fixed ? 'fixed' : 'absolute' }}
  >
    <Loader>
      <MultiLangText text="loading" />
      ...
    </Loader>
  </Dimmer>
);

export default Loading;
