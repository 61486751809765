import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

class AdblockModal extends Component {
  render() {
    const { open } = this.props;

    if (!open) return null;

    return (
      <StyledModal id="modal">
        <ModalBody>
          <div style={{ textAlign: 'center' }}>
            <h3 style={{ marginTop: '0.75rem', opacity: '0.8' }}>
              系統偵測到您正在使用廣告阻擋程式(擴充軟體)
            </h3>
            <h3 style={{ opacity: '0.8' }}>
              為支持網站運作，煩請您暫時關閉廣告阻擋程式並重新整理頁面
            </h3>
            <h3 style={{ opacity: '0.8' }}>謝謝您！</h3>
          </div>
        </ModalBody>
      </StyledModal>
    );
  }
}

AdblockModal.propTypes = {
  open: PropTypes.bool,
};

AdblockModal.defaultProps = {
  open: false,
};

export default AdblockModal;

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  vertical-align: middle;
  z-index: 1000;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  position: relative;
  background-color: #fff;
  border: none;
  color: #000;
  text-align: left;
  width: 600px;
  min-height: 155px;
  margin: 5rem auto;
  border-radius: 7px;
  overflow: hidden;
  padding: 2rem;

  @media only screen and (max-width: 420px) {
    width: 90%;
  }

  .title {
    @media only screen and (max-width: 420px) {
      font-size: 16px;
    }
  }

  img {
    @media only screen and (max-width: 420px) {
      width: 85%;
    }
  }
`;
