import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon, Form, TextArea, Header } from 'semantic-ui-react';
import cn from 'classnames';
import MultiLangText from './MultiLangText';
import CloseIcon from './CloseIcon';
import emailjs from 'emailjs-com';

import feature2 from './images/feature2.png';
import feature3 from './images/feature3.png';
import feature1 from './images/feature1.png';
import feature1_1 from './images/feature1_1.png';

class AboutModal extends Component {
  state = { intro: null, moreRecommand: false };

  closeModal = (e) => {
    const { ...rest } = this.props;

    if (rest.dimmerClick && e.target.id === 'modal') {
      rest.dimmerClick();
      this.setState({ moreRecommand: false });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    return this.setState({ [name]: value.trim() });
  };

  handleIntroChange = (e) => this.setState({ intro: e.target.value });

  render() {
    const { open } = this.props;
    const { intro, moreRecommand } = this.state;

    if (!open) return null;

    return (
      <StyledModal onClick={this.closeModal} id="modal">
        <ModalBody>
          <div style={{ textAlign: 'center' }}>
            <h2 style={{ margin: '0' }}>台灣圖書館電子書搜尋</h2>
            <h3 style={{ marginTop: '0.75rem', opacity: '0.6' }}>
              一鍵搜尋全台公立圖書館
            </h3>
          </div>

          {/* <div style={{ textAlign: 'left', marginTop: '2rem' }}>
            <h5 style={{ opacity: '0.6', margin: '0' }}>
              ❊感謝雲爸的3c學園分享 -{' '}
              <a
                href="https://dacota.tw/blog/post/taiwanlibrarysearch"
                target="_blank"
              >
                書蟲注意!
                免費的電子書「台灣圖書館電子書搜尋」一鍵找出想看的書在哪裡
              </a>
            </h5>
            <h5 style={{ opacity: '0.6', margin: '0' }}>
              ❊感謝經理人分享 -{' '}
              <a
                href="https://www.managertoday.com.tw/articles/view/62937"
                target="_blank"
              >
                一次搜尋台灣公立圖書館電子書！免出門、免費，只要註冊帳號就能線上讀
              </a>
            </h5>
            <h5 style={{ opacity: '0.6', margin: '0' }}>
              ❊感謝重灌狂人分享 -{' '}
              <a href="https://briian.com/74781/" target="_blank">
                免費看書！「台灣圖書館電子書搜尋」可一次搜足各圖書館的電子書資源
              </a>
            </h5>

            {moreRecommand ? (
              <div>
                <h5 style={{ opacity: '0.6', margin: '0' }}>
                  ❊感謝電腦王阿達分享 -{' '}
                  <a
                    href="https://www.kocpc.com.tw/archives/382248"
                    target="_blank"
                  >
                    一鍵搜尋全台公立圖書館電子書的網站，大量正版電子書免費看到飽
                  </a>
                </h5>
                <h5 style={{ opacity: '0.6', margin: '0' }}>
                  ❊感謝小孜女孩分享 -{' '}
                  <a
                    href="https://www.youtube.com/watch?v=QbW1srrvMnU&t=1s"
                    target="_blank"
                  >
                    旅外台灣人看過來！好用平台分享！
                  </a>
                </h5>
                <h5 style={{ opacity: '0.6', margin: '0' }}>
                  ❊感謝免費資源網路社群分享 -{' '}
                  <a
                    href="https://free.com.tw/taiwanlibrarysearch/"
                    target="_blank"
                  >
                    一鍵快速搜尋全台灣公立圖書館電子書，註冊帳號免費線上閱讀
                  </a>
                </h5>
                <h5 style={{ opacity: '0.6', margin: '0' }}>
                  ❊感謝就是教不落分享 -{' '}
                  <a href="https://steachs.com/archives/56342" target="_blank">
                    台灣圖書館電子書搜尋工具，一鍵找出全台公立圖書館有沒有你想看的電子書
                  </a>
                </h5>
              </div>
            ) : (
              <h5
                style={{
                  opacity: '0.8',
                  marginTop: '1rem',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
                onClick={() => this.setState({ moreRecommand: true })}
              >
                更多部落客/Youtuber推薦分享
              </h5>
            )}
          </div> */}

          <div style={{ textAlign: 'left', marginTop: '2rem' }}>
            <h3
              style={{ borderLeft: '3px solid black', paddingLeft: '8px' }}
              className="title"
            >
              作者的話
            </h3>
            <p style={{ marginLeft: '1rem', opacity: '0.7' }}>
              「台灣圖書館電子書搜尋」
              上線至今也已經兩年多了，期間陸續收到不少正面的回饋，能透過自己的專業架設網站，幫助到大家搜尋到想看的書，很有成就感！
            </p>
            <p style={{ marginLeft: '1rem', opacity: '0.7' }}>
              這網站也算有一定數量的使用者，請容許我在這邊利用自己建的網站幫自己打個廣告：我目前住在多倫多，在今年開始從事房地產行業，是安大略省合格持有證照的
              realestate
              salesperson，如果您，或是您有親朋好友住在多倫多，可能會有房產交易/租屋需求，歡迎透過右上角的「有話想說」
              聯繫我！
            </p>
            <h3
              style={{ borderLeft: '3px solid black', paddingLeft: '8px' }}
              className="title"
            >
              免費資源
            </h3>
            <p style={{ marginLeft: '1rem', opacity: '0.7' }}>
              台灣許多公立圖書館其實都有跟
              「HyRead」、「udn」、「台灣雲端書庫」、「華藝電子書」合作，提供免費的電子書借閱，可以直接線上觀看，透過iPad，或是HyRead
              的閱讀器閱讀。
            </p>
            <p style={{ marginLeft: '1rem', opacity: '0.7' }}>
              而且那些圖書館又可以線上辦證，不必一定要是該縣市居民，等於只要在家辦辦帳號，就可以享有這些免費資源。這些圖書館包括了
              國立臺灣圖書館 / 台北市立圖書館 / 新北市立圖書館 / 台中市立圖書館
              等等。
            </p>
            <p style={{ marginLeft: '1rem', opacity: '0.7' }}>
              這邊附上這些圖書館的線上辦證連結。直接點選下面的圖書館，前往辦證（若連結失效，可再自行google）
            </p>
            <p style={{ textAlign: 'center' }}>
              {' '}
              <a
                href="https://www.ebookservice.tw/#membership/intro"
                target="_blank"
                style={{ cursor: 'pointer' }}
              >
                台灣雲端書庫
              </a>
            </p>
            <p style={{ textAlign: 'center' }}>
              {' '}
              <a
                href="http://cis.ntl.edu.tw/F?func=file&file_name=net-bor-new"
                target="_blank"
                style={{ cursor: 'pointer' }}
              >
                國立臺灣圖書館
              </a>
            </p>
            <p style={{ textAlign: 'center' }}>
              {' '}
              <a
                href="https://book.tpml.edu.tw/webpac/registration.jsp"
                target="_blank"
                style={{ cursor: 'pointer' }}
              >
                台北市圖書館
              </a>
            </p>
            <p style={{ textAlign: 'center' }}>
              {' '}
              <a
                href="https://webpac.tphcc.gov.tw/webpac/web_register.cfm"
                target="_blank"
                style={{ cursor: 'pointer' }}
              >
                新北市圖書館
              </a>
            </p>
            <p style={{ textAlign: 'center' }}>
              {' '}
              <a
                href="https://ipac.library.taichung.gov.tw/webpac/web_register.cfm"
                target="_blank"
                style={{ cursor: 'pointer' }}
              >
                台中市圖書館
              </a>
            </p>
          </div>
          <div style={{ textAlign: 'left', marginTop: '2rem' }}>
            <h3
              style={{ borderLeft: '3px solid black', paddingLeft: '8px' }}
              className="title"
            >
              整合搜尋
            </h3>
            <p style={{ marginLeft: '1rem', opacity: '0.7' }}>
              本網站支援
              <br />
              <br />
              <b>國立臺灣圖書館</b>
              ：HyRead」、「udn」、「台灣雲端書庫」、「華藝電子書」
              <br /> <b>台北市圖書館</b>：「HyRead」、「udn」
              <br /> <b>新北市圖書館</b> -「HyRead」、「udn」
              <br />
              <b>台中市圖書館</b>：「HyRead」、「udn」
              <br />
              <b>基隆市文化局</b>：「HyRead」、「udn」
              <br />
              <b>桃園市立圖書館</b>：「HyRead」、「udn」
              <br />
              <b>新竹市文化局圖書館</b>：「HyRead」
              <br />
              <b>新竹縣政府文化局</b>：「HyRead」
              <br />
              <b>苗栗市立圖書館</b>：「HyRead」
              <br />
              <b>苗栗縣立圖書館</b>：「HyRead」、「udn」
              <br />
              <b>彰化雲端電子書庫</b>：「HyRead」
              <br />
              <b>嘉義市政府文化局</b>：「HyRead」、「udn」
              <br />
              <b>臺南市立圖書館</b>：「HyRead」
              <br />
              <b>高雄市立圖書館</b>：「HyRead」、「udn」
              <br />
              <b>宜蘭縣政府文化局</b>：「HyRead」、「udn」
              <br />
              <b>花蓮縣文化局</b>：「HyRead」、「udn」
              <br />
              <b>臺東縣政府文化處</b>：「HyRead」、「udn」
              <br />
              <b>澎湖縣圖書館</b>：「HyRead」、「udn」
              <br />
              <b>金門縣文化局</b>：「HyRead」
              <br />
              <b>連江縣公共圖書館</b>：「HyRead」
              <br />
              <br />
              只要在搜尋欄中輸入書籍關鍵字並按下搜尋，就知道在這些圖書館有沒有這本書。一開始預設搜尋所有圖書館，可以點擊
              "更多選項"，調整想要搜尋的圖書館。
            </p>
            <div style={{ margin: '0 auto', textAlign: 'center' }}>
              <img src={feature1} />
            </div>
            <div style={{ margin: '0 auto', textAlign: 'center' }}>
              <img src={feature1_1} />
            </div>
          </div>
          <div style={{ textAlign: 'left', marginTop: '2rem' }}>
            <h3
              style={{ borderLeft: '3px solid black', paddingLeft: '8px' }}
              className="title"
            >
              同步即時排行榜
            </h3>
            <p style={{ marginLeft: '1rem', opacity: '0.7' }}>
              我們可能會覺得圖書館不會有太新、太熱門的書籍，本網站加入搜尋書商「HyRead」、「博客來」即時排行榜的功能，利用這個功能可以發現，其實在榜單上的熱門新書，有不少能夠在圖書館找到。
            </p>
            <div style={{ margin: '0 auto', textAlign: 'center' }}>
              <img src={feature2} />
            </div>

            <p style={{ margin: '2rem 0 0 1rem', opacity: '0.7' }}>
              點擊封面圖片，前往圖書館搜尋。
            </p>
            <div style={{ margin: '0 auto', textAlign: 'center' }}>
              <img src={feature3} />
            </div>
            <div style={{ textAlign: 'left', marginTop: '2rem' }}>
              <h3
                style={{ borderLeft: '3px solid black', paddingLeft: '8px' }}
                className="title"
              >
                找到書之後
              </h3>
              <p style={{ marginLeft: '1rem', opacity: '0.7' }}>
                在各圖書館線上辦完證後，並在這邊找到想看的書，下一步就是要前往圖書館借閱那本書，可以透過
                App，或是直接前往圖書館網站線上觀看，這邊附上各{' '}
                <b>圖書館說明</b> 及 <b>App下載</b>&nbsp;連結。
              </p>
              <ul>
                <li style={{ margin: '1rem auto' }}>HyRead：</li>
                <ul>
                  <li style={{ marginBottom: '0.7rem' }}>
                    <a
                      href="https://ntledu.ebook.hyread.com.tw/Template/RWD3.0/policy.jsp"
                      target="_blank"
                    >
                      國立臺灣圖書館 - HyRead
                    </a>
                  </li>
                  <li style={{ marginBottom: '0.7rem' }}>
                    ios -&nbsp;
                    <a
                      href="https://apps.apple.com/tw/app/hyread-%E9%9B%BB%E5%AD%90%E6%9B%B8/id1098612822"
                      target="_blank"
                    >
                      HyRead 電子書
                    </a>
                  </li>
                  <li>
                    android -&nbsp;
                    <a
                      href="https://play.google.com/store/apps/details?id=com.hyread.reader.v3&hl=zh_TW&gl=US"
                      target="_blank"
                    >
                      HyRead 電子書
                    </a>
                  </li>
                </ul>
                <li style={{ margin: '1rem auto' }}>udn</li>
                <ul>
                  <li style={{ marginBottom: '0.7rem' }}>
                    <a
                      href="https://reading.udn.com/udnlib/cwktrial/howto/know"
                      target="_blank"
                    >
                      國立臺灣圖書館 - udn
                    </a>
                  </li>
                  <li style={{ marginBottom: '0.7rem' }}>
                    ios -&nbsp;
                    <a
                      href="https://apps.apple.com/tw/app/udn-%E8%AE%80%E6%9B%B8%E9%A4%A8/id1342153816"
                      target="_blank"
                    >
                      udn 讀書館
                    </a>
                  </li>
                  <li>
                    android -&nbsp;
                    <a
                      href="https://play.google.com/store/apps/details?id=com.udn.dp.books.lib.android&hl=zh_TW&gl=US"
                      target="_blank"
                    >
                      udn 讀書館
                    </a>
                  </li>
                </ul>
                <li style={{ margin: '1rem auto' }}>台灣雲端書庫</li>
                <ul>
                  <li style={{ marginBottom: '0.7rem' }}>
                    <a
                      href="https://lib.ebookservice.tw/ntl2/#service/user-guide"
                      target="_blank"
                    >
                      國立臺灣圖書館 - 台灣雲端書庫
                    </a>
                  </li>
                  <li style={{ marginBottom: '0.7rem' }}>
                    ios -&nbsp;
                    <a
                      href="https://apps.apple.com/tw/app/%E5%8F%B0%E7%81%A3%E9%9B%B2%E7%AB%AF%E6%9B%B8%E5%BA%AB/id675068310"
                      target="_blank"
                    >
                      台灣雲端書庫
                    </a>
                  </li>
                  <li>
                    android -&nbsp;
                    <a
                      href="https://play.google.com/store/apps/details?id=tw.ebookservice.voler&hl=zh_TW&gl=US"
                      target="_blank"
                    >
                      台灣雲端書庫
                    </a>
                  </li>
                </ul>
                <li style={{ margin: '1rem auto' }}>華藝電子書</li>
                <ul>
                  <li style={{ marginBottom: '0.7rem' }}>
                    <a
                      href="https://www.airitibooks.com/Other/Newbie?NewbieType=5"
                      target="_blank"
                    >
                      國立臺灣圖書館 - 台灣雲端書庫
                    </a>
                  </li>
                  <li style={{ marginBottom: '0.7rem' }}>
                    ios -&nbsp;
                    <a
                      href="https://apps.apple.com/tw/app/airiti-reader/id1486515085"
                      target="_blank"
                    >
                      Airiti Reader
                    </a>
                  </li>
                  <li>
                    android -&nbsp;
                    <a
                      href="https://play.google.com/store/apps/details?id=com.airiti.airitireader&hl=zh_TW&gl=US"
                      target="_blank"
                    >
                      Airiti Reader
                    </a>
                  </li>
                </ul>
              </ul>
            </div>
          </div>
          <CloseIcon close={this.props.closeModal} />
        </ModalBody>
      </StyledModal>
    );
  }
}

AboutModal.propTypes = {
  open: PropTypes.bool,
};

AboutModal.defaultProps = {
  open: false,
};

export default AboutModal;

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  vertical-align: middle;
  z-index: 1000;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  position: relative;
  background-color: #fff;
  border: none;
  color: #000;
  text-align: left;
  width: 55rem;
  min-height: 30rem;
  margin: 3rem auto;
  border-radius: 7px;
  overflow: hidden;
  padding: 2rem;

  @media only screen and (max-width: 420px) {
    width: 90%;
  }

  .title {
    @media only screen and (max-width: 420px) {
      font-size: 16px;
    }
  }

  img {
    @media only screen and (max-width: 420px) {
      width: 85%;
    }
  }
`;
