import React, { Component } from 'react';
import MultiLangText from './MultiLangText';
import styled from 'styled-components';
import Loading from './loading';
import './i18n';
import books from './static/books.json';
import Image from './Image';
import {
  FacebookShareButton,
  FacebookIcon,
  LineShareButton,
  LineIcon,
} from 'react-share';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { colors } from './utils/colors';

const screenWidth = window && window.innerWidth;
const mobildWidth = 1000;

export default class Library extends Component {
  state = { copied: false };

  componentDidMount() {
    const script = document.createElement('script');

    script.src = '//cdn.holmesmind.com/js/init.js';
    script.async = true;

    document.body.appendChild(script);
  }

  setCopied = () => this.setState({ copied: true });

  getBookList = () => {
    const { previewNumbers, setPreviewNumbers } = this.props;
    const total = books.list.length;
    let nums = [];

    while (nums.length < 6) {
      const randomNum = Math.floor(Math.random() * total);

      if (!nums.includes(randomNum)) {
        nums.push(randomNum);
      }
    }

    if (previewNumbers.length) {
      nums = previewNumbers;
    } else {
      setPreviewNumbers(nums);
    }

    return nums.reduce((result, n) => {
      result.push({
        book_name: books.list[n] ? books.list[n].book_name : '',
        image: books.list[n] ? books.list[n].image : '',
      });

      return result;
    }, []);
  };

  generatePreviewList = (list) => {
    const { setSearchFirstThenSubmit } = this.props;

    return (
      <ListContainer>
        {list.map((book, index) => (
          <PreviewItem key={index}>
            <Image
              src={book.image}
              fallbackSrc="https://upload.wikimedia.org/wikipedia/commons/1/14/No_Image_Available.jpg"
              onClick={() =>
                setSearchFirstThenSubmit(book.book_name.substring(0, 8))
              }
              style={{ cursor: 'pointer' }}
            />
            <br />
            <p>
              <b>{book.book_name}</b>
            </p>
          </PreviewItem>
        ))}
      </ListContainer>
    );
  };

  render() {
    return (
      <div style={{ padding: '1rem 0' }}>
        {screenWidth < mobildWidth ? null : (
          <div
            style={{ position: 'relative' }}
            dangerouslySetInnerHTML={{
              __html:
                '<ins \
                class="clickforceads" \
                style="position: absolute; right: 0.2rem; transform: scale(0.8);" \
                data-ad-zone="12454" \
              ></ins>',
            }}
          ></div>
        )}

        {screenWidth < mobildWidth ? null : (
          <div
            style={{ position: 'relative' }}
            dangerouslySetInnerHTML={{
              __html:
                '<ins \
                class="clickforceads" \
                style="position: absolute; left: 0.2rem; transform: scale(0.8);" \
                data-ad-zone="12455" \
              ></ins>',
            }}
          ></div>
        )}

        <StyledH3
          style={{
            margin: '0',
            paddingBottom: '1rem',
            position: 'relative',
          }}
        >
          <MultiLangText text="able_search" />
        </StyledH3>

        {this.generatePreviewList(this.getBookList())}

        <div
          style={{
            paddingBottom: '2rem',
            margin: '0 auto',
          }}
        >
          <div>
            <StyledH3
              style={{
                margin: '0',
                paddingBottom: '1rem',
                position: 'relative',
              }}
            >
              歡迎分享
            </StyledH3>
          </div>
          <div
            style={{
              maxWidth: '60rem',
              borderTop: '1px solid rgba(0,0,0,0.3)',
              margin: '0 auto',
              paddingTop: '1rem',
            }}
          >
            <FacebookShareButton
              url={'https://taiwanlibrarysearch.herokuapp.com/'}
              style={{ marginRight: '0.3rem' }}
            >
              <FacebookIcon borderRadius={45} size={45} />
            </FacebookShareButton>
            <LineShareButton
              url={'https://taiwanlibrarysearch.herokuapp.com/'}
              style={{ marginLeft: '0.3rem' }}
            >
              <LineIcon borderRadius={45} size={45} />
            </LineShareButton>
            <div
              style={{
                marginTop: '0.75rem',
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <div>
                <StyledInput value="https://taiwanlibrarysearch.herokuapp.com/" />
                <CopyToClipboard
                  text="https://taiwanlibrarysearch.herokuapp.com/"
                  onCopy={() => this.setCopied()}
                >
                  <CopyButton>
                    <i className="copy icon" />
                    複製連結
                  </CopyButton>
                </CopyToClipboard>
              </div>
            </div>
          </div>
        </div>
        {screenWidth < mobildWidth ? (
          <div
            dangerouslySetInnerHTML={{
              __html:
                '<ins \
                class="clickforceads" \
                style="display: inline-block;" \
                data-ad-zone="12456" \
              ></ins>',
            }}
          ></div>
        ) : null}
      </div>
    );
  }
}

const ListContainer = styled.div`
  margin: 0 auto 2rem;
  padding-top: 1rem;
  max-width: 60rem;
  border-top: 1px solid rgba(0, 0, 0, 0.3);

  @media (max-width: 375px) {
    width: 95%;
  }
`;

const PreviewItem = styled.div`
  display: inline-block;
  vertical-align: top;
  margin: 1rem;

  p {
    max-width: 16rem;
    margin: 0 auto;

    @media (max-width: 375px) {
      max-width: 10rem;
    }

    @media (max-width: 320px) {
      max-width: 8rem;
    }
  }

  img {
    width: 16rem;

    @media (max-width: 375px) {
      width: 10rem;
    }

    @media (max-width: 320px) {
      max-width: 8rem;
    }
  }
`;

const StyledH3 = styled.h3`
  display: inline-block;

  &:after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -1px;
    width: 100%;
    height: 3px;
    background: ${colors.mainGreen};
    border-radius: 8px;
  }
`;

const CopyButton = styled.button`
  width: 7.8rem;
  height: 35px;
  border: 0;
  background-color: ${colors.mainGreen};
  border: 2px solid ${colors.mainGreen};
  color: white;
  transform: translateX(-1px);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  opacity: ${(props) => (props.disable ? '0.8' : '1')};
  cursor: pointer;
  font-weight: bold;
  vertical-align: top;
`;

const StyledInput = styled.input`
  width: 22rem;
  height: 35px;
  padding: 0.25rem 0.5rem;
  border: 2px solid ${colors.mainGreen};
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;

  &:focus-visible {
    outline: none !important;
  }

  @media (max-width: 640px) {
    width: 12rem;
  }
`;
