import React, { Component, Suspense, lazy } from 'react';
import MultiLangText from './MultiLangText';
import styled from 'styled-components';
import AboutModal from './AboutModal';
import FeedbackModal from './FeedbackModal';
import buyMeCoffee from './images/bmc.png';
import { Icon, Popup } from 'semantic-ui-react';

const LanguageSelector = React.lazy(() => import('./languageSelector'));

export default class NavBar extends Component {
  state = { open: false };

  closeModal = () => this.setState({ open: false });

  render() {
    const {
      setRankList,
      setRankListVisible,
      rankListVisible,
      setSubRankListVisible,
      subRankListVisible,
    } = this.props;
    const { open } = this.state;

    return (
      <Nav>
        <div className="item">
          小額捐款
          <br />
          <a
            href="https://www.buymeacoffee.com/twlibraryebook/membership"
            target="_blank"
          >
            <img
              src={buyMeCoffee}
              height="28"
              width="auto"
              alt="buymecoffee"
              style={{ marginTop: '0.1rem' }}
            />
          </a>
        </div>
        <div className="item">
          <MultiLangText text="about" />
          <br />
          <img
            src="https://img.icons8.com/pastel-glyph/64/000000/info--v1.png"
            style={{ height: '29px ', cursor: 'pointer' }}
            onClick={() => this.setState({ open: 'about' })}
          />
        </div>
        <div className="item">
          <MultiLangText text="feedback" />
          <br />
          <img
            src="https://img.icons8.com/ios/50/000000/speech-bubble-with-dots.png"
            style={{ height: '30px ', cursor: 'pointer' }}
            onClick={() => this.setState({ open: 'feedback' })}
          />
        </div>
        <Popup
          hoverable
          trigger={
            <div className="item last">
              <MultiLangText text="ranking" />
              <br />
              <img
                className="trigger"
                src="https://img.icons8.com/ios/50/000000/leaderboard.png"
                style={{ height: '30px' }}
                /* onClick={() => {
              setRankListVisible(!rankListVisible);
              setSubRankListVisible(false);
            }} */
              />
            </div>
          }
          position="bottom right"
          style={{ transform: 'translateX(-15px)' }}
        >
          免費伺服器流量無法負荷繼續提供 "排行榜" 服務，敬請見諒。
        </Popup>

        {/* <div className="item last">
          <Suspense fallback={<div>Loading...</div>}>
            <LanguageSelector updateLanguage={this.props.updateLanguage} />
          </Suspense>
        </div> */}

        <RankingList visible={rankListVisible}>
          <Rank
            onClick={() => {
              if (subRankListVisible === 'hyread') {
                setSubRankListVisible(false);
              } else {
                setSubRankListVisible('hyread');
              }

              setRankList('');
            }}
          >
            HyRead
          </Rank>
          <Rank
            onClick={() => {
              if (subRankListVisible === 'berkeley') {
                setSubRankListVisible(false);
              } else {
                setSubRankListVisible('berkeley');
              }

              setRankList('');
            }}
          >
            博客來
          </Rank>
          <Rank
            onClick={() => {
              if (subRankListVisible === 'readmoo') {
                setSubRankListVisible(false);
              } else {
                setSubRankListVisible('readmoo');
              }

              setRankList('');
            }}
          >
            Readmoo 讀墨
          </Rank>
          {/* <Rank disabled>樂天 Kobo</Rank>
          <Rank disabled>Taaze</Rank> */}
        </RankingList>
        <SubRankList visible={subRankListVisible === 'hyread'} store="hyread">
          <Rank onClick={() => setRankList('bestSelling')}>暢銷排行</Rank>
          <Rank onClick={() => setRankList('rentalRating')}>租書排行</Rank>
          <Rank onClick={() => setRankList('magazineRating')}>雜誌排行</Rank>
          <Rank onClick={() => setRankList('trialRating')}>試閱排行</Rank>
        </SubRankList>
        <SubRankList
          visible={subRankListVisible === 'berkeley'}
          store="berkeley"
        >
          <Rank onClick={() => setRankList('thirtyDays')}>30日排行</Rank>
          <Rank onClick={() => setRankList('yearly')}>2020排行</Rank>
        </SubRankList>
        <SubRankList visible={subRankListVisible === 'readmoo'} store="readmoo">
          <Rank onClick={() => setRankList('instant')}>綜合榜</Rank>
          <Rank onClick={() => setRankList('sale')}>暢銷榜</Rank>
          <Rank onClick={() => setRankList('reading')}>閱讀榜</Rank>
        </SubRankList>

        <AboutModal
          open={open === 'about'}
          dimmerClick={() => this.closeModal()}
          closeModal={this.closeModal}
        />
        <FeedbackModal
          open={open === 'feedback'}
          dimmerClick={() => this.closeModal()}
          closeModal={this.closeModal}
        />
      </Nav>
    );
  }
}

const Nav = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 2rem;
  font-size: 15px;
  font-weight: 500;

  @media (max-width: 320px) {
    right: 1.5rem;
    font-size: 14px;
  }

  .item {
    display: inline-block;
    border-top: 3px solid rgba(0, 0, 0, 0.7);
    padding-top: 0.25rem;
    vertical-align: top;
    margin-right: 1rem;
    width: 5rem;

    @media (max-width: 320px) {
      width: 4rem;
      margin-right: 0.8rem;
    }

    &.last {
      margin-right: 0;
      width: 5.5rem;
      opacity: 0.3;
    }
  }
`;

const RankingList = styled.div`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  transition: opacity 200ms ease, top 150ms ease;
  background: #c8c8c8;
  width: 12rem;
  padding: 0.1rem 0.75rem;
  position: absolute;
  z-index: 2;
  right: 3.25rem;
  top: 4.5rem;
  border-radius: 8px;

  @media (max-width: 320px) {
    right: 0.25rem;
  }
`;

const Rank = styled.div`
  background: #fafafa;
  border-radius: 4px;
  margin: 0.8rem auto;
  padding: 0.5rem;
  cursor: pointer;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
`;

const SubRankList = styled.div`
  opacity: ${(props) => (props.visible ? 1 : 0)};
  visibility: ${(props) => (props.visible ? 'visible' : 'hidden')};
  transition: opacity 200ms ease, left 150ms ease;
  background: #c8c8c8;
  width: 8rem;
  padding: 0.1rem 0.75rem;
  position: absolute;
  z-index: 2;
  right: 15.3rem;
  border-radius: 8px;

  @media (max-width: 320px) {
    right: 12.3rem;
  }

  top: ${(props) =>
    props.store === 'hyread'
      ? '6.5rem'
      : props.store === 'berkeley'
      ? '9.5rem'
      : props.store === 'readmoo'
      ? '12.5rem'
      : 0};
`;
