import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './index.css';
import App from './App';

Sentry.init({
  dsn: 'https://add35d4ca49f4880970429a51e875658@o890244.ingest.sentry.io/5839328',
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 0.5,
});

ReactDOM.render(<App />, document.getElementById('root'));
