import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon, Form, TextArea } from 'semantic-ui-react';
import cn from 'classnames';
import MultiLangText from './MultiLangText';
import CloseIcon from './CloseIcon';
import emailjs from 'emailjs-com';

class FeedbackModal extends Component {
  state = {
    email: '',
    message: '',
    loading: false,
  };

  closeModal = (e) => {
    const { ...rest } = this.props;

    return rest.dimmerClick && e.target.id === 'modal'
      ? rest.dimmerClick()
      : false;
  };

  handleChange = (e) => {
    const { name, value } = e.target;

    return this.setState({ [name]: value.trim() });
  };

  sendEmail = (e) => {
    e.preventDefault();

    this.setState({ loading: true });

    emailjs
      .sendForm(
        'service_7iemzwu',
        'template_4su7ua2',
        e.target,
        'user_r29kIEi0kyfZnb0Bcp4oc'
      )
      .then(
        () => {
          this.setState({ loading: false });
          this.props.closeModal();
        },
        () => {
          this.setState({ loading: false });
          this.props.closeModal();
        }
      );
  };

  render() {
    const { open, ...rest } = this.props;
    const { loading, email, message } = this.state;
    const validateEmail = (email) => {
      return String(email)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    if (!open) return null;

    return (
      <StyledModal onClick={this.closeModal} id="modal">
        <ModalBody>
          <Form onSubmit={this.sendEmail}>
            <Label>
              <MultiLangText text="Email" />
            </Label>
            <Form.Input
              type="email"
              name="email"
              placeholder="(必填)"
              onChange={this.handleChange}
              style={{ width: '15rem', marginTop: '0.5rem' }}
            />
            <Label>
              <MultiLangText text="comment" />
            </Label>
            <TextArea
              name="message"
              placeholder="..."
              style={{ height: '12rem', marginTop: '0.5rem' }}
              onChange={this.handleChange}
            />
            <StyledButton
              className={cn({
                loading,
                disabled: !email || !validateEmail(email) || message === '',
              })}
              disabled={!email || !validateEmail(email) || message === ''}
            >
              <MultiLangText text="submit_comment" />
            </StyledButton>
          </Form>
          <CloseIcon close={this.props.closeModal} />
        </ModalBody>
      </StyledModal>
    );
  }
}

FeedbackModal.propTypes = {
  open: PropTypes.bool,
};

FeedbackModal.defaultProps = {
  open: false,
};

export default FeedbackModal;

const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  vertical-align: middle;
  z-index: 1000;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.5);
`;

const ModalBody = styled.div`
  position: relative;
  background-color: #fff;
  border: none;
  color: #000;
  text-align: left;
  width: 33rem;
  height: 28rem;
  margin: 3rem auto;
  border-radius: 7px;
  overflow: hidden;
  padding: 2rem;

  @media only screen and (max-width: 420px) {
    width: 90%;
  }
`;

const Label = styled.label`
  font-size: 1.1rem;
`;

const StyledButton = styled(({ className, children, ...rest }) => (
  <Button className={className} {...rest}>
    {children}
  </Button>
))`
  float: right;
  margin-top: 2rem !important;
  background: unset !important;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;

  &.loading {
    cursor: default;
    color: transparent !important;
    pointer-events: none !important;
    transition: none !important;
    box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -0.6em 0 0 -0.6em;
      width: 16px;
      height: 16px;
      border-radius: 500rem;
      border: 0.2em solid rgba(0, 0, 0, 0.15);
      animation: spin 0.6s linear infinite;
      border-top-color: #767676;
    }
    &:focus,
    &:active {
      color: transparent !important;
    }
    .icon {
      display: none;
    }
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }

  &.disabled {
    cursor: default;
    pointer-events: none !important;
    opacity: 0.45;
  }
`;
